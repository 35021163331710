import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "hidden", "loading" ]
  static targets = [ "abridgedContent", "expandedContent", "toggler" ]

  static values = {
    abridgeLabel: String,
    unabridgeLabel: String
  }

  connect() {
    this.#abridge()
    this.togglerTarget.classList.remove(this.loadingClass)
  }

  toggle() {
    if (this.abridged) {
      this.#unabridge()
    } else {
      this.#abridge()
    }
  }

  // Private
  #unabridge() {
    this.expandedContentTarget.classList.remove(this.hiddenClass)
    this.abridgedContentTarget.classList.add(this.hiddenClass)
    this.togglerTarget.textContent = this.abridgeLabelValue

    this.abridged = false
  }

  #abridge() {
    this.expandedContentTarget.classList.add(this.hiddenClass)
    this.abridgedContentTarget.classList.remove(this.hiddenClass)
    this.togglerTarget.textContent = this.unabridgeLabelValue

    this.abridged = true
  }
}
